import * as React from 'react';

function SvgCloudMoon(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M7 8a3.5 3.5 0 013.5 3.555.5.5 0 00.625.492A1.503 1.503 0 0113 13.5a1.5 1.5 0 01-1.5 1.5H3a2 2 0 11.1-3.998.5.5 0 00.509-.375A3.502 3.502 0 017 8zm4.473 3a4.5 4.5 0 00-8.72-.99A3 3 0 003 16h8.5a2.5 2.5 0 000-5h-.027z' />
			<path d='M11.286 1.778a.5.5 0 00-.565-.755 4.595 4.595 0 00-3.18 5.003 5.46 5.46 0 011.055.209A3.603 3.603 0 019.83 2.617a4.593 4.593 0 004.31 5.744 3.576 3.576 0 01-2.241.634c.162.317.295.652.394 1a4.59 4.59 0 003.624-2.04.5.5 0 00-.565-.755 3.593 3.593 0 01-4.065-5.422z' />
		</svg>
	);
}

export default SvgCloudMoon;
