import * as React from 'react';

function SvgWifi(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M15.384 6.115a.485.485 0 00-.047-.736A12.444 12.444 0 008 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 00-.048.736.518.518 0 00.668.05A11.448 11.448 0 018 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z' />
			<path d='M13.229 8.271a.482.482 0 00-.063-.745A9.455 9.455 0 008 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 00-.063.745.525.525 0 00.652.065A8.46 8.46 0 018 7a8.46 8.46 0 014.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 008 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 018 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 008 11.5a1.99 1.99 0 00-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 00.707 0l.707-.707z' />
		</svg>
	);
}

export default SvgWifi;
