import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'document-type';

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}?${query}`, { headers: await authHeader() });
};

export default { create, read };
