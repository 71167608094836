import * as React from 'react';

const SvgCustomAsignment = (props) => (
	<svg
		width='26'
		height='29'
		viewBox='0 0 26 29'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		className='svg-icon'
		{...props}>
		<path
			d='M25.6654 22.0013H21.6654V18.0013H18.9987V22.0013H14.9987V24.668H18.9987V28.668H21.6654V24.668H25.6654M2.9987 0.667969C2.29145 0.667969 1.61318 0.94892 1.11308 1.44902C0.612983 1.94911 0.332031 2.62739 0.332031 3.33464V24.668C0.332031 26.148 1.5187 27.3346 2.9987 27.3346H13.412C12.932 26.508 12.5987 25.6013 12.4387 24.668H2.9987V3.33464H12.332V10.0013H18.9987V15.4413C19.4387 15.3746 19.892 15.3346 20.332 15.3346C20.7854 15.3346 21.2254 15.3746 21.6654 15.4413V8.66797L13.6654 0.667969M5.66536 14.0013V16.668H16.332V14.0013M5.66536 19.3346V22.0013H12.332V19.3346H5.66536Z'
			fill='#6C5DD3'
		/>
	</svg>
);

export default SvgCustomAsignment;
