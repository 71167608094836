import * as React from 'react';

function SvgFlower3(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M11.424 8c.437-.052.811-.136 1.04-.268a2 2 0 00-2-3.464c-.229.132-.489.414-.752.767C9.886 4.63 10 4.264 10 4a2 2 0 10-4 0c0 .264.114.63.288 1.035-.263-.353-.523-.635-.752-.767a2 2 0 00-2 3.464c.229.132.603.216 1.04.268-.437.052-.811.136-1.04.268a2 2 0 102 3.464c.229-.132.489-.414.752-.767C6.114 11.37 6 11.736 6 12a2 2 0 104 0c0-.264-.114-.63-.288-1.035.263.353.523.635.752.767a2 2 0 102-3.464c-.229-.132-.603-.216-1.04-.268zM9 4a1.468 1.468 0 01-.045.205c-.039.132-.1.295-.183.484a12.88 12.88 0 01-.637 1.223L8 6.142a21.73 21.73 0 01-.135-.23 12.88 12.88 0 01-.637-1.223 4.216 4.216 0 01-.183-.484A1.473 1.473 0 017 4a1 1 0 112 0zM3.67 5.5a1 1 0 011.366-.366 1.472 1.472 0 01.156.142c.094.1.204.233.326.4.245.333.502.747.742 1.163l.13.232a21.86 21.86 0 01-.265.002 12.88 12.88 0 01-1.379-.06 4.214 4.214 0 01-.51-.083 1.47 1.47 0 01-.2-.064A1 1 0 013.67 5.5zm1.366 5.366a1 1 0 01-1-1.732c.001 0 .016-.008.047-.02.037-.013.087-.028.153-.044.134-.032.305-.06.51-.083a12.88 12.88 0 011.379-.06c.09 0 .178 0 .266.002a21.82 21.82 0 01-.131.232c-.24.416-.497.83-.742 1.163a4.1 4.1 0 01-.327.4 1.483 1.483 0 01-.155.142zM9 12a1 1 0 01-2 0 1.476 1.476 0 01.045-.206c.039-.131.1-.294.183-.483.166-.378.396-.808.637-1.223L8 9.858l.135.23c.241.415.47.845.637 1.223.083.19.144.352.183.484A1.338 1.338 0 019 12zm3.33-6.5a1 1 0 01-.366 1.366 1.478 1.478 0 01-.2.064c-.134.032-.305.06-.51.083-.412.045-.898.061-1.379.06-.09 0-.178 0-.266-.002l.131-.232c.24-.416.497-.83.742-1.163a4.1 4.1 0 01.327-.4c.046-.05.085-.086.114-.11.026-.022.04-.03.041-.032a1 1 0 011.366.366zm-1.366 5.366a1.494 1.494 0 01-.155-.141 4.225 4.225 0 01-.327-.4A12.88 12.88 0 019.74 9.16a22 22 0 01-.13-.232l.265-.002c.48-.001.967.015 1.379.06.205.023.376.051.51.083.066.016.116.031.153.044l.048.02a1 1 0 11-1 1.732zM8 9a1 1 0 110-2 1 1 0 010 2z' />
		</svg>
	);
}

export default SvgFlower3;
