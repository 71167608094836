import * as React from 'react';

function SvgFestival(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M5.24 14.42c-.04 1.76-.18 3.72-.58 5.58h2.67c.27-1.94.43-4.07.52-5.93-.31-.17-.6-.37-.85-.63-.47.49-1.08.83-1.76.98zM11 11H8c0 .83.67 1.5 1.5 1.5S11 11.83 11 11zM6 11H3c0 .83.67 1.5 1.5 1.5S6 11.83 6 11zM12 4.71C10.67 5.85 8.51 7.55 5.89 9h12.23C15.49 7.55 13.33 5.85 12 4.71zM19.5 12.5c.83 0 1.5-.67 1.5-1.5h-3c0 .83.67 1.5 1.5 1.5zM14.5 12.5c.83 0 1.5-.67 1.5-1.5h-3c0 .83.67 1.5 1.5 1.5zM17 13.44c-.25.25-.54.45-.85.62.1 1.87.26 4 .52 5.93h2.67c-.39-1.86-.54-3.82-.57-5.58-.69-.14-1.3-.48-1.77-.97z'
				opacity={0.3}
			/>
			<path d='M12 2S7 7 1 9v2c0 1.49.93 2.75 2.24 3.26C3.2 16.76 2.92 19.69 2 22h20c-.92-2.31-1.2-5.24-1.24-7.74A3.496 3.496 0 0023 11V9c-6-2-11-7-11-7zm0 2.71c1.33 1.14 3.49 2.84 6.11 4.29H5.89C8.51 7.55 10.67 5.85 12 4.71zM3 11h3c0 .83-.67 1.5-1.5 1.5S3 11.83 3 11zm4.33 9H4.66c.39-1.86.54-3.82.57-5.58.68-.15 1.29-.49 1.76-.98.25.25.54.45.85.62-.08 1.87-.24 4-.51 5.94zM8 11h3c0 .83-.67 1.5-1.5 1.5S8 11.83 8 11zm1.35 9c.24-1.83.39-3.78.48-5.53.84-.08 1.61-.45 2.17-1.02.56.57 1.32.94 2.17 1.02.1 1.75.24 3.7.48 5.53h-5.3zm5.15-7.5c-.83 0-1.5-.67-1.5-1.5h3c0 .83-.67 1.5-1.5 1.5zm2.17 7.5c-.27-1.94-.43-4.07-.52-5.93.31-.17.61-.37.85-.62.47.48 1.08.83 1.76.98.03 1.76.18 3.72.57 5.58h-2.66zm2.83-7.5c-.83 0-1.5-.67-1.5-1.5h3c0 .83-.67 1.5-1.5 1.5z' />
		</svg>
	);
}

export default SvgFestival;
