import AuthService from '../services/auth.service';

const logout = (removeCookie) => {
	return AuthService.logout(removeCookie);
};
const goONELNKHomapage = () => {
	return AuthService.goONELNKHomapage();
};
const generateToken = () => {
	return AuthService.generateToken();
};

const getMenu = (role) => {
	return AuthService.getMenu(role).then(
		(data) => {
			return Promise.resolve(data);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

const getMenuByRoleAndSegment = (role_code, segment_code) => {
	return AuthService.getMenuByRoleAndSegment(role_code, segment_code).then(
		(data) => {
			return Promise.resolve(data);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

const login = (username, password) => {
	return AuthService.login(username, password).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const loginPhone = (payload) => {
	return AuthService.loginPhone(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const loginSSO = (payload) => {
	return AuthService.loginSSO(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};
const getMenuByUsername = (role) => {
	return AuthService.getMenuByUsername(role).then(
		(data) => {
			return Promise.resolve(data);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

export default {
	login,
	loginSSO,
	getMenu,
	logout,
	generateToken,
	getMenuByUsername,
	goONELNKHomapage,
	getMenuByRoleAndSegment,
	loginPhone,
};
