import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import AuthModule from '../../../modules/AuthModule';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { getRequester } from '../../../helpers/helpers';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import RequestModule from '../../../modules/sodiq/DocumentRequestModule';
import Badge from '../../../components/bootstrap/Badge';

const SwitchRole = ({ roles }) => {
	const { department, position_code, position_name, position, version } = getRequester();
	const [selectedRole] = useState(roles.filter((e) => e?.position_code === position_code));
	const [anotherRole] = useState(roles.filter((e) => e?.position_code !== position_code));

	const onChange = async (_position_code) => {
		let _selectRole = selectedRole;
		let is_change = false;
		const new_roles = roles.map((e) => {
			let is_selected = false;
			if (e?.position_code === _position_code) {
				_selectRole = e;
				is_selected = true;
				is_change = true;
			}
			return { ...e, is_selected };
		});

		if (is_change) {
			AuthModule.getMenu({ role: _selectRole?.role_name })
				.then(() => {
					localStorage.setItem('roles', JSON.stringify(_selectRole?.role_name));
					localStorage.setItem('position', JSON.stringify(_selectRole));
					localStorage.setItem('positions', JSON.stringify(new_roles));
					window.location.reload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Warning!',
						text: 'Role not found',
						icon: 'info',
					});
				})
				.finally(() => {});
		}
	};

	return (
		<div className='col-auto'>
			<Dropdown direction='down'>
				<DropdownToggle hasIcon>
					<div>
						<Button color='primary'>
							<div>
								{version === 3 ? position?.org_name_short : department} -{' '}
								{version === 3 ? position?.position_name : position_name}
							</div>
						</Button>
					</div>
				</DropdownToggle>
				<DropdownMenu>
					{anotherRole.map((e, i) => (
						<DropdownItem key={i} isHeader isDivider isText>
							<Button onClick={() => onChange(e?.position_code)}>{`(${
								version === 3 ? e?.org_name_short : e?.department_name
							}) - ${e?.position_name}`}</Button>
						</DropdownItem>
					))}{' '}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

SwitchRole.propTypes = {
	roles: PropTypes.arrayOf(Object),
};

SwitchRole.defaultProps = {
	roles: [],
};

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren, roles, handleReloadData }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [count, setCount] = useState(0);
	const { username, person_name } = getRequester();
	const navigate = useNavigate();
	const location = useLocation();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const fetchData = () => {
		const params = {
			page: 1,
			sizePerPage: 100,
			username,
		};
		RequestModule.readNotif(new URLSearchParams(params))
			.then((res) => {
				setNotifications(res.foundData);
				setCount(res.countData);
			})
			.catch(() => {})
			.finally(() => {});
	};

	const handleOpen = () => {
		fetchData();
		setOffcanvasStatus(true);
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { i18n } = useTranslation();

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const navToUrl = (item, subject) => {
		localStorage.setItem('title_doc', item.document_title);
		if (subject === 'Approval QAS' && location.pathname === '/document/qas-process') {
			handleReloadData();
			setOffcanvasStatus(false);
		}
		if (subject === 'Approval QAS' && location.pathname !== '/document/qas-process') {
			navigate('/document/qas-process');
		}
		if (subject !== 'Approval QAS' && location.pathname === '/document/approval') {
			handleReloadData();
			setOffcanvasStatus(false);
		}
		if (subject !== 'Approval QAS' && location.pathname !== '/document/approval') {
			navigate('/document/approval');
		}
	};

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/*  Role Switch */}
				<div className='col-auto d-flex flex-column justify-content-center'>
					<h5>You are logged in as</h5>
				</div>
				<SwitchRole roles={roles} />

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>
				{/*	Notifications */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => {
							handleOpen();
						}}
						aria-label='Notifications'>
						{count > 0 && (
							<Badge
								color='danger'
								className='badge bg-danger top-5 translate-middle'>
								{count > 99 ? '99+' : count}
								<span className='visually-hidden'>unread notifications</span>
							</Badge>
						)}
					</Button>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row overflow-y-auto px-3'>
						{notifications.map((notification) => {
							let subject = '';
							if (notification.status === 'Submitted') {
								subject = 'Approval Creator';
							}
							if (notification.status === 'Approval Creator') {
								subject = 'Approval QAS';
							}
							if (notification.status === 'Approval QAS') {
								subject = 'Approval Checker';
							}
							if (notification.status === 'Approval Checker') {
								subject = 'Final Approval';
							}
							const datas = notification?.approval_history.filter(
								(e) => e.status === notification.status,
							);
							const momentDate = datas[0]?.created_at
								? moment(datas[0]?.created_at)
								: null;

							return (
								<Alert
									isLight
									key={notification?._id}
									bodyClassName='w-100'
									icon='CircleNotifications'
									isOutline
									isRounded
									shadow='md'
									color='primary'
									className={`flex-nowrap my-2 border-0 ${
										notification?.isRead ? 'opacity-50' : 'opacity-100'
									}`}
									onClick={() => {}}>
									<div className='row justify-content-between w-100'>
										<div
											className={
												darkModeStatus ? 'col text-light' : 'col text-dark'
											}
											role='button'
											tabIndex={0}
											onClick={() => navToUrl(notification, subject)}
											onKeyDown={() => {}}>
											<strong>Hello, {person_name}</strong>
											<br />
											{`[${subject}] `}
											{notification?.document_title}
											<br />
											<span
												style={{
													fontSize: '0.75rem',
												}}
												className={`
												${!notification?.isRead ? 'text-primary' : undefined}
											 fw-semibold`}>
												{momentDate &&
													`${momentDate.fromNow()} ${momentDate.format(
														'(HH:mm, DD MMMM YYYY)',
													)}`}
											</span>
										</div>
									</div>
								</Alert>
							);
						})}
					</div>
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
	roles: PropTypes.oneOfType([PropTypes.array]),
	handleReloadData: PropTypes.func,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
	roles: [],
	handleReloadData: () => {},
};

export default CommonHeaderRight;
