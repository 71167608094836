import * as React from 'react';

function SvgSnow2(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8 16a.5.5 0 01-.5-.5v-1.293l-.646.647a.5.5 0 01-.707-.708L7.5 12.793v-1.086l-.646.647a.5.5 0 01-.707-.708L7.5 10.293V8.866l-1.236.713-.495 1.85a.5.5 0 11-.966-.26l.237-.882-.94.542-.496 1.85a.5.5 0 11-.966-.26l.237-.882-1.12.646a.5.5 0 01-.5-.866l1.12-.646-.884-.237a.5.5 0 11.26-.966l1.848.495.94-.542-.882-.237a.5.5 0 11.258-.966l1.85.495L7 8l-1.236-.713-1.849.495a.5.5 0 11-.258-.966l.883-.237-.94-.542-1.85.495a.5.5 0 01-.258-.966l.883-.237-1.12-.646a.5.5 0 11.5-.866l1.12.646-.237-.883a.5.5 0 01.966-.258l.495 1.849.94.542-.236-.883a.5.5 0 01.966-.258l.495 1.849 1.236.713V5.707L6.147 4.354a.5.5 0 11.707-.708l.646.647V3.207L6.147 1.854a.5.5 0 11.707-.708l.646.647V.5a.5.5 0 011 0v1.293l.647-.647a.5.5 0 11.707.708L8.5 3.207v1.086l.647-.647a.5.5 0 11.707.708L8.5 5.707v1.427l1.236-.713.495-1.85a.5.5 0 11.966.26l-.236.882.94-.542.495-1.85a.5.5 0 11.966.26l-.236.882 1.12-.646a.5.5 0 01.5.866l-1.12.646.883.237a.5.5 0 11-.26.966l-1.848-.495-.94.542.883.237a.5.5 0 11-.26.966l-1.848-.495L9 8l1.236.713 1.849-.495a.5.5 0 01.259.966l-.883.237.94.542 1.849-.495a.5.5 0 01.259.966l-.883.237 1.12.646a.5.5 0 01-.5.866l-1.12-.646.236.883a.5.5 0 11-.966.258l-.495-1.849-.94-.542.236.883a.5.5 0 01-.966.258L9.736 9.58 8.5 8.866v1.427l1.354 1.353a.5.5 0 01-.707.708l-.647-.647v1.086l1.354 1.353a.5.5 0 01-.707.708l-.647-.647V15.5a.5.5 0 01-.5.5z' />
		</svg>
	);
}

export default SvgSnow2;
