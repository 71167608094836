import * as React from 'react';

function SvgVolumeDownFill(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M9 4a.5.5 0 00-.812-.39L5.825 5.5H3.5A.5.5 0 003 6v4a.5.5 0 00.5.5h2.325l2.363 1.89A.5.5 0 009 12V4zm3.025 4a4.486 4.486 0 01-1.318 3.182L10 10.475A3.489 3.489 0 0011.025 8 3.49 3.49 0 0010 5.525l.707-.707A4.486 4.486 0 0112.025 8z' />
		</svg>
	);
}

export default SvgVolumeDownFill;
