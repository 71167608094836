import Service from '../../services/sodiq/document-type.service';

const create = (payload) => {
	return Service.create(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const read = (query) => {
	return Service.read(query).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { create, read };
