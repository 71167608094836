import GeneralSettingModule from '../services/general-setting.service';

const getAccessKPIReport = (payload) => {
	return GeneralSettingModule.getAccessKPIReport(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const getAccessInputKPI = (payload) => {
	return GeneralSettingModule.getAccessInputKPI(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const getAccessPAReport = (payload) => {
	return GeneralSettingModule.getAccessPAReport(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const getAccessDocument = (payload) => {
	return GeneralSettingModule.getAccessDocument(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { getAccessKPIReport, getAccessInputKPI, getAccessPAReport, getAccessDocument };
