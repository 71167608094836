import ChatService from '../services/chat.service';

const readChat = (payload) => {
	return ChatService.readChat(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const send = (payload) => {
	return ChatService.send(payload)
		.then(
			(response) => {
				return Promise.resolve(response.data, response.data.message);
			},
			(error) => {
				const message =
					(error.response && error.response.data && error.response.data.message) ||
					error.message ||
					error.toString();
				return Promise.reject(message);
			},
		)
		.catch((error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		});
};

const maxChat = (payload) => {
	return ChatService.maxChat(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default {
	readChat,
	send,
	maxChat,
};
