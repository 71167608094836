// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable prettier/prettier */
import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const readChat = async (query) => {
	return axios.get(`${API_URL_DEFAULT}chatbot/formatted?${query}`, {
		headers: await authHeader(),
	});
};

const maxChat = async (query) => {
	return axios.get(`${API_URL_DEFAULT}chatbot/max-chat?${query}`, {
		headers: await authHeader(),
	});
};

const send = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}chatbot`, payload, { headers: await authHeader() });
};

export default {
	readChat,
	send,
	maxChat,
};
