import * as React from 'react';

function SvgFileExcel(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M5.18 4.616a.5.5 0 01.704.064L8 7.219l2.116-2.54a.5.5 0 11.768.641L8.651 8l2.233 2.68a.5.5 0 01-.768.64L8 8.781l-2.116 2.54a.5.5 0 01-.768-.641L7.349 8 5.116 5.32a.5.5 0 01.064-.704z' />
			<path d='M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2H4zm0 1h8a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1z' />
		</svg>
	);
}

export default SvgFileExcel;
