import * as React from 'react';

function SvgSnow3(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8 7.5a.5.5 0 100 1 .5.5 0 000-1z' />
			<path d='M8 16a.5.5 0 01-.5-.5v-1.293l-.646.647a.5.5 0 01-.707-.708L7.5 12.793v-1.51l-2.053-1.232-1.348.778-.495 1.85a.5.5 0 11-.966-.26l.237-.882-1.12.646a.5.5 0 01-.5-.866l1.12-.646-.883-.237a.5.5 0 11.258-.966l1.85.495L5 9.155v-2.31l-1.4-.808-1.85.495a.5.5 0 11-.259-.966l.884-.237-1.12-.646a.5.5 0 01.5-.866l1.12.646-.237-.883a.5.5 0 11.966-.258l.495 1.849 1.348.778L7.5 4.717v-1.51L6.147 1.854a.5.5 0 11.707-.708l.646.647V.5a.5.5 0 011 0v1.293l.647-.647a.5.5 0 11.707.708L8.5 3.207v1.51l2.053 1.232 1.348-.778.495-1.85a.5.5 0 11.966.26l-.236.882 1.12-.646a.5.5 0 01.5.866l-1.12.646.883.237a.5.5 0 11-.26.966l-1.848-.495-1.4.808v2.31l1.4.808 1.849-.495a.5.5 0 11.259.966l-.883.237 1.12.646a.5.5 0 01-.5.866l-1.12-.646.236.883a.5.5 0 11-.966.258l-.495-1.849-1.348-.778L8.5 11.283v1.51l1.354 1.353a.5.5 0 01-.707.708l-.647-.647V15.5a.5.5 0 01-.5.5zm2-6.783V6.783l-2-1.2-2 1.2v2.434l2 1.2 2-1.2z' />
		</svg>
	);
}

export default SvgSnow3;
