import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typist from 'react-typist';
import { CodeBlock, a11yDark, a11yLight } from 'react-code-blocks';
import Avatar from './Avatar';
import useDarkMode from '../hooks/useDarkMode';

export const ChatListItem = ({
	src,
	srcSet,
	className,
	isOnline,
	color,
	size,
	name,
	surname,
	latestMessage,
	unreadMessage,
	isActive,
	lastSeenTime,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('col-12 cursor-pointer', className)} {...props}>
			<div
				className={classNames(
					'd-flex align-items-center',
					'p-3 rounded-2',
					'transition-base',
					{
						'bg-l25-info-hover': !darkModeStatus,
						'bg-lo50-info-hover': darkModeStatus,
						'bg-l10-info': !darkModeStatus && isActive,
						'bg-lo25-info': darkModeStatus && isActive,
					},
				)}>
				<ChatAvatar
					src={src}
					srcSet={srcSet}
					isOnline={isOnline}
					unreadMessage={unreadMessage}
					color={color}
					size={size}
					className='me-3'
				/>
				<div className='d-grid'>
					<div className='d-flex flex-wrap d-xxl-block'>
						<span className='fw-bold fs-5 me-3'>{`${name} ${surname}`}</span>
						{lastSeenTime && (
							<small
								className={classNames(
									'text-info fw-bold px-3 py-1 rounded-pill align-top text-nowrap',
									{
										'bg-l10-info': !darkModeStatus,
										'bg-lo25-info': darkModeStatus,
									},
								)}>
								{lastSeenTime}
							</small>
						)}
					</div>
					<div className='text-muted text-truncate'>{latestMessage}</div>
				</div>
			</div>
		</div>
	);
};
ChatListItem.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	isOnline: PropTypes.bool,
	color: PropTypes.string,
	size: PropTypes.number,
	name: PropTypes.string.isRequired,
	surname: PropTypes.string.isRequired,
	latestMessage: PropTypes.string,
	unreadMessage: PropTypes.number,
	isActive: PropTypes.bool,
	lastSeenTime: PropTypes.string,
};
ChatListItem.defaultProps = {
	srcSet: null,
	className: null,
	isOnline: false,
	color: 'primary',
	size: 64,
	latestMessage: null,
	unreadMessage: null,
	isActive: false,
	lastSeenTime: null,
};

export const ChatHeader = ({ to }) => {
	return (
		<>
			<strong className='me-2'>To:</strong>
			{to}
		</>
	);
};
ChatHeader.propTypes = {
	to: PropTypes.string.isRequired,
};

export const ChatMessages = ({ onFinishTyping, startTyping, messages, isReply, ...props }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const { darkModeStatus } = useDarkMode();
	const typeDelay = 30; // millisecond

	const FormatAnswer = () => {
		const style_pre = {
			display: 'inline',
			margin: 0,
			padding: 0,
			fontSize: '1em',
			lineHeight: 1.5,
			backgroundColor: 'transparent',
			border: 'none',
			whiteSpace: 'pre-wrap',
			wordWrap: 'break-word',
		};

		// if string has no split, then no code block
		let formatted = null;

		const splitted = messages.split('```');
		formatted = splitted.map((msg, id) => {
			// description if even, code block if odd
			if (id % 2 === 0) {
				if (startTyping) {
					return (
						<Typist
							cursor={{
								show: true,
								blink: true,
								hideWhenDone: true,
								hideWhenDoneDelay: 500,
							}}
							avgTypingDelay={typeDelay}
							stdTypingDelay={0}>
							<pre style={style_pre}>{msg}</pre>
						</Typist>
					);
				}
				return <pre style={style_pre}>{msg}</pre>;
			}
			const split_langg = msg.split('\n');
			const language = split_langg[0];
			const text = msg.replace(`${language}\n`, '');

			return (
				<>
					<CodeBlock
						theme={darkModeStatus ? a11yDark : a11yLight}
						text={language}
						showLineNumbers={false}
					/>
					<CodeBlock
						theme={darkModeStatus ? a11yDark : a11yLight}
						text={text.replace(/\n$/, '')}
						language={language}
						showLineNumbers
						wrapLines
						codeBlock
					/>
				</>
			);
		});

		return formatted;
	};

	const GenereteTimeouts = () => {
		const temp_timeouts = [];
		const splitted = messages.split('```');
		if (splitted.length === 0) {
			return temp_timeouts;
		}
		if (startTyping) {
			let prev_msg = '';
			splitted.forEach((msg, id) => {
				if (id % 2 === 0) {
					prev_msg = msg;
					temp_timeouts.push(100);
				} else if (prev_msg) {
					const count_timeout = typeDelay * prev_msg.length + typeDelay * 50;
					temp_timeouts.push(count_timeout);
				}
				return 0;
			});
		}
		return temp_timeouts;
	};

	const [answer] = useState(FormatAnswer());
	const [timeouts] = useState(GenereteTimeouts());

	useEffect(() => {
		if (
			startTyping &&
			answer.length > 1 &&
			currentIndex < answer.length &&
			timeouts.length === answer.length
		) {
			const timer = setTimeout(() => {
				setCurrentIndex((prevIndex) => prevIndex + 1);
			}, timeouts[currentIndex]);

			return () => clearTimeout(timer);
		}
		if (currentIndex == answer.length) {
			onFinishTyping(true);
		}
		return null;
	}, [currentIndex]);

	const ShowAnswer = () => {
		if (answer.length > 1 && startTyping) {
			return answer
				?.slice(0, currentIndex)
				.map((jsxElement, index) => <div key={index}>{jsxElement}</div>);
		}
		return answer;
	};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className='chat-messages' {...props}>
			<div className={classNames('chat-message', { 'chat-message-reply': isReply })}>
				{ShowAnswer()}
			</div>
		</div>
	);
};
ChatMessages.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	onFinishTyping: PropTypes.func,
	startTyping: PropTypes.bool,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
	isReply: PropTypes.bool,
};
ChatMessages.defaultProps = {
	onFinishTyping: null,
	startTyping: false,
	isReply: false,
};

export const ChatAvatar = ({
	src,
	srcSet,
	className,
	color,
	unreadMessage,
	isOnline,
	size,
	...props
}) => {
	return (
		<div
			className={classNames('chat-avatar', className)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			<div className='position-relative'>
				{src && <Avatar srcSet={srcSet} src={src} size={size} color={color} />}
				{unreadMessage && (
					<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
						{unreadMessage} <span className='visually-hidden'>unread messages</span>
					</span>
				)}
				{isOnline && (
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				)}
			</div>
		</div>
	);
};
ChatAvatar.propTypes = {
	src: PropTypes.string,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	unreadMessage: PropTypes.number,
	isOnline: PropTypes.bool,
	size: PropTypes.number,
};
ChatAvatar.defaultProps = {
	src: null,
	srcSet: null,
	className: null,
	color: null,
	unreadMessage: null,
	isOnline: false,
	size: 45,
};

export const ChatGroup = ({
	onFinishTyping,
	startTyping,
	isReply,
	messages,
	isOnline,
	color,
	user,
	bottomRef,
	...props
}) => {
	const _Avatar = (
		<ChatAvatar
			src={user.src}
			srcSet={user.srcSet}
			username={user.username}
			name={user.name}
			surname={user.surname}
			isOnline={user.isOnline}
			color={user.color}
		/>
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('chat-group', { 'chat-group-reply': isReply })} {...props}>
			{!isReply && _Avatar}
			<ChatMessages
				startTyping={startTyping}
				messages={messages}
				ref={bottomRef}
				isReply={isReply}
				onFinishTyping={onFinishTyping}
			/>
			{isReply && _Avatar}
		</div>
	);
};
ChatGroup.propTypes = {
	onFinishTyping: PropTypes.func,
	startTyping: PropTypes.bool,
	bottomRef: PropTypes.func,
	isReply: PropTypes.bool,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
	isOnline: PropTypes.bool,
	color: PropTypes.string,
	user: PropTypes.shape({
		src: PropTypes.string,
		srcSet: PropTypes.string,
		username: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		isOnline: PropTypes.bool,
		color: PropTypes.string,
	}).isRequired,
};
ChatGroup.defaultProps = {
	onFinishTyping: null,
	startTyping: false,
	bottomRef: null,
	isReply: false,
	isOnline: false,
	color: null,
};

const Chat = ({ children, className }) => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('chat-container', className)}>{children}</div>
	);
};
Chat.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
Chat.defaultProps = {
	className: null,
};

export default Chat;
