import * as React from 'react';

function SvgLampFill(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path
				fillRule='evenodd'
				d='M5.04.303A.5.5 0 015.5 0h5c.2 0 .38.12.46.303l3 7a.5.5 0 01-.363.687h-.002c-.15.03-.3.056-.45.081a32.731 32.731 0 01-4.645.425V13.5a.5.5 0 11-1 0V8.495a32.753 32.753 0 01-4.645-.425c-.15-.025-.3-.05-.45-.08h-.003a.5.5 0 01-.362-.688l3-7z'
			/>
			<path d='M6.493 12.574a.5.5 0 01-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 00-.37.265.301.301 0 00-.052.075l-.001.004-.004.01V14l.002.008a.147.147 0 00.016.033.62.62 0 00.145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.62.62 0 00.146-.15.148.148 0 00.015-.033L12 14v-.004a.301.301 0 00-.057-.09 1.318 1.318 0 00-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 11.164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 01.575.411z' />
		</svg>
	);
}

export default SvgLampFill;
