import axios from 'axios';
import jwt_decode from 'jwt-decode';
import history from '../helpers/history';
import refreshToken from './refresh-token';

const API_URL = process.env.REACT_APP_API;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;
const appName = process.env.REACT_APP_NAME;

const reactiveToken = async (token, type = 'user') => {
	const jsonToken = JSON.parse(localStorage.getItem(token));
	const decodedToken = jwt_decode(jsonToken);
	if (type == 'user') {
		const { username, password } = decodedToken;
		return axios
			.post(`${API_URL}auth/signin`, { username, password }, { headers: refreshToken() })
			.then(async (response) => {
				if (response.data.accessToken) {
					const verifyToken = jwt_decode(response.data.accessToken);

					localStorage.setItem('accessToken', JSON.stringify(response.data));
					localStorage.setItem('appToken', JSON.stringify(verifyToken));
				}

				return response;
			});
	}
	const payload = {
		'x-public-key': publicKey,
		'x-app-name': appName,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};
	return axios
		.post(
			`${API_URL}auth/app-token`,
			{},
			{
				headers: payload,
			},
		)
		.then(async (response) => {
			if (response.data.appToken) {
				localStorage.setItem('appToken', JSON.stringify(response.data));
			}

			return response;
		});
};
const checkLastAnyMinute = async (token) => {
	let isLastMinute = false;
	const decodedToken = jwt_decode(token);
	const dateNow = new Date().getTime();
	const calcSecond = (decodedToken.exp * 1000 - dateNow) / 1000;
	if (calcSecond > 0 && calcSecond <= parseFloat(process.env.REACT_APP_SESSION_REFRESH_TOKEN))
		isLastMinute = true;
	return { isLastMinute };
};
const checkSessionExpired = async (token) => {
	let isExpired = false;
	const decodedToken = jwt_decode(token);

	const dateNow = new Date().getTime();
	if (decodedToken.exp * 1000 < dateNow) isExpired = true;
	return { isExpired };
};
const authHeader = async () => {
	const token = JSON.parse(localStorage.getItem('accessToken'));
	const applicationToken = JSON.parse(localStorage.getItem('appToken'));
	if (!applicationToken) {
		return {
			'x-public-key': publicKey,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		};
	}
	if (token && token.accessToken && applicationToken) {
		// app
		const showExpiredApp = await checkSessionExpired(applicationToken.appToken);
		if (showExpiredApp.isExpired) {
			localStorage.setItem('message', 'Session is expired. Please login again');
			localStorage.removeItem('appToken');
			localStorage.removeItem('accessToken');
			history.push('/login');
			window.location.reload();
		}

		const checkLastFiveMinuteApp = await checkLastAnyMinute(applicationToken.appToken);
		if (checkLastFiveMinuteApp.isLastMinute)
			await reactiveToken(applicationToken.appToken, 'app');
		// user
		const showExpired = await checkSessionExpired(token.accessToken);
		if (showExpired.isExpired) {
			localStorage.setItem('message', 'Session is expired. Please login again');
			localStorage.removeItem('appToken');
			localStorage.removeItem('accessToken');
			history.push('/login');
			window.location.reload();
		}

		const checkLastFiveMinute = await checkLastAnyMinute(token.accessToken);
		if (checkLastFiveMinute.isLastMinute) await reactiveToken(token.accessToken, 'user');
		return {
			'x-public-key': publicKey,
			'x-application-token': `Bearer ${applicationToken.appToken}`,
			'x-user-token': `Bearer ${token.accessToken}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		};
	}
	return {
		'x-public-key': publicKey,
		'x-application-token': `Bearer ${applicationToken.appToken}`,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};
};
export default authHeader;
