import classNames from 'classnames';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import Button from '../components/bootstrap/Button';
import Card, { CardBody } from '../components/bootstrap/Card';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Input from '../components/bootstrap/forms/Input';
import Tooltips from '../components/bootstrap/Tooltips';
import LogoLNK from '../components/LogoLNK';
import useDarkMode from '../hooks/useDarkMode';
import Page from '../layout/Page/Page';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import AuthModule from '../modules/AuthModule';
import Spinner from '../components/bootstrap/Spinner';

const cPasKey = 'password';
const cUKey = 'username';

const NewLogin = ({ isLoggedIn }) => {
	const { darkModeStatus } = useDarkMode();
	const [showPassword, setShowPassword] = useState(false);

	const navigate = useNavigate();
	const accessToken = localStorage.getItem('accessToken');
	const appToken = localStorage.getItem('appToken');
	const localName = process.env.REACT_APP_NAME;
	const decodeToken = appToken ? jwt_decode(appToken) : null;

	const [loadingLogin, setLoadingLogin] = useState(false);

	const [status] = useState({
		loading: false,
		isLoggedIn,
		token: null,
		username: '',
		password: '',
	});

	// formik login
	const formikLogin = useFormik({
		initialValues: { username: '', password: '' },
		validate: (values) => {
			const errors = {};

			if (!values[cUKey]) {
				errors[cUKey] = 'Required';
			}
			if (!values[cPasKey]) {
				errors[cPasKey] = 'Required';
			}

			return errors;
		},
		onSubmit: (values) => {
			setLoadingLogin(true);

			AuthModule.login(values.username.toUpperCase(), values.password)
				.then(async (res) => {
					if (res?.accessToken) {
						const verifyToken = jwt_decode(res.accessToken);
						localStorage.setItem('accessToken', JSON.stringify(res));
						localStorage.setItem('photo', JSON.stringify({ ...res?.photo }));
						localStorage.setItem('positions', JSON.stringify(res?.positions));

						const position = res?.positions?.find((find) => find?.is_default);
						localStorage.setItem('position', JSON.stringify(position));

						let role = 'guest user';
						if (verifyToken.details.hris_org_tree.current_person) {
							role = `${verifyToken.details.hris_org_tree.current_person.nama_department} ${verifyToken.details.hris_org_tree.current_person.nama_posisi}`;
							localStorage.setItem('roles', JSON.stringify(role));

							// get menu
							await AuthModule.getMenu({ role }).then(() => {
								window.location.reload();
							});
						}
					}
				})
				.catch((err) => {
					Swal.fire({
						heightAuto: false,
						title: 'Warning!',
						text: err,
						icon: 'info',
					});
				})
				.finally(() => {
					setLoadingLogin(false);
				});
		},
	});

	// useeffects status
	useEffect(() => {
		const fetchLogin = async () => {
			if (!status.isLoggedIn && !status.loading && !accessToken) {
				AuthModule.generateToken();
				return false;
			}

			const parseAccessToken = accessToken && JSON.parse(accessToken);
			if (parseAccessToken && decodeToken && decodeToken?.name === localName) {
				navigate('/home');
			}
			return false;
		};

		fetchLogin();
	}, [status]);

	const loginComponent = () => {
		return (
			<Card tag='form' noValidate onSubmit={formikLogin.handleSubmit}>
				<CardBody>
					<div className={classNames('text-center', 'my-2')}>
						<Link
							to='/'
							className={classNames('text-decoration-none', 'fw-bold', 'display-2', {
								'text-dark': !darkModeStatus,
								'text-light': darkModeStatus,
							})}>
							<LogoLNK width={200} />
						</Link>

						<div className={classNames('text-center', 'h1', 'fw-bold', 'mt-5')}>
							Welcome,
						</div>
						<div className={classNames('text-center', 'h4', 'text-muted', 'mb-5')}>
							Sign in to continue!
						</div>

						<div className={classNames('py-2')}>
							<FormGroup
								id='username'
								autoComplete='off'
								isFloating
								label='Employee ID'>
								<Input
									onChange={formikLogin.handleChange}
									onBlur={formikLogin.handleBlur}
									className={classNames('uppercase')}
									isValid={formikLogin.isValid}
									invalidFeedback={formikLogin.errors.username}
									value={formikLogin.values.username}
									isTouched={formikLogin.touched.username}
								/>
							</FormGroup>
						</div>

						<div className={classNames('col-12', 'py-2', 'd-flex')}>
							<div className={classNames('flex-grow-1')}>
								<FormGroup
									id='password'
									autoComplete='off'
									isFloating
									label='Password'>
									<Input
										type={showPassword ? 'text' : 'password'}
										onChange={formikLogin.handleChange}
										onBlur={formikLogin.handleBlur}
										isValid={formikLogin.isValid}
										invalidFeedback={formikLogin.errors.password}
										value={formikLogin.values.password}
										isTouched={formikLogin.touched.password}
									/>
								</FormGroup>
							</div>
							<div>
								<Tooltips title={showPassword ? 'Hide' : 'Show'} placement='top'>
									<Button
										icon={showPassword ? 'eye_slash' : 'eye'}
										type='button'
										size='lg'
										color={darkModeStatus ? 'dark' : 'light'}
										className={classNames('ms-1')}
										onClick={() => setShowPassword(!showPassword)}
									/>
								</Tooltips>
							</div>
						</div>

						<div className={classNames('py-2')}>
							<Button
								type={loadingLogin ? 'button' : 'submit'}
								color='warning'
								className={classNames(
									'w-100',
									'py-3',
									'd-flex',
									'justify-content-center',
									'align-items-center',
								)}>
								{loadingLogin && (
									<Spinner
										isGrow
										isSmall
										inButton='onlyIcon'
										className={classNames('me-2')}
									/>
								)}
								Login
							</Button>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	};

	return (
		<PageWrapper title='Login' className={classNames('bg-info')}>
			<Page className={classNames('p-0')}>
				<div
					className={classNames(
						'row',
						'h-100',
						'align-items-center',
						'justify-content-center',
					)}>
					<div
						className={classNames(
							'col-xl-4',
							'col-lg-6',
							'col-md-8',
							'shadow-3d-container',
						)}>
						{/* Render Component */}
						{loginComponent()}

						<div className={classNames('text-center')}>
							<a
								href='/privacy-policy'
								className={classNames('link-light text-decoration-none mx-2')}>
								Privacy policy
							</a>
							<a
								href='/terms-and-conditions'
								className={classNames('link-light text-decoration-none mx-2')}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

NewLogin.propTypes = {
	isLoggedIn: PropTypes.bool,
};
NewLogin.defaultProps = {
	isLoggedIn: false,
};

export default NewLogin;
