import * as React from 'react';

const SvgCustomFilter = (props) => (
	<svg
		width='17'
		height='19'
		viewBox='0 0 17 19'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		className='svg-icon'
		{...props}>
		<path
			d='M11.0011 16.88C11.0411 17.18 10.9411 17.5 10.7111 17.71C10.6186 17.8027 10.5087 17.8762 10.3877 17.9264C10.2668 17.9766 10.1371 18.0024 10.0061 18.0024C9.87514 18.0024 9.74546 17.9766 9.62448 17.9264C9.50351 17.8762 9.39362 17.8027 9.30111 17.71L5.29111 13.7C5.18204 13.5934 5.09911 13.463 5.04881 13.319C4.9985 13.175 4.98217 13.0213 5.00111 12.87V7.75L0.211108 1.62C0.0487158 1.41153 -0.0245586 1.14726 0.00729555 0.88493C0.0391497 0.622602 0.173543 0.383546 0.381108 0.22C0.571108 0.08 0.781108 0 1.00111 0H15.0011C15.2211 0 15.4311 0.08 15.6211 0.22C15.8287 0.383546 15.9631 0.622602 15.9949 0.88493C16.0268 1.14726 15.9535 1.41153 15.7911 1.62L11.0011 7.75V16.88ZM3.04111 2L7.00111 7.06V12.58L9.00111 14.58V7.05L12.9611 2H3.04111Z'
			fill='#46BCAA'
		/>
	</svg>
);

export default SvgCustomFilter;
