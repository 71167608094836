import * as React from 'react';

function SvgSuitClub(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8 1a3.25 3.25 0 00-3.25 3.25c0 .186 0 .29.016.41.014.12.045.27.12.527l.19.665-.692-.028a3.25 3.25 0 102.357 5.334.5.5 0 01.844.518l-.003.005-.006.015-.024.055a21.893 21.893 0 01-.438.92 22.38 22.38 0 01-1.266 2.197c-.013.018-.02.05.001.09.01.02.021.03.03.036A.036.036 0 005.9 15h4.2c.01 0 .016-.002.022-.006a.092.092 0 00.029-.035c.02-.04.014-.073.001-.091a22.875 22.875 0 01-1.704-3.117l-.024-.054-.006-.015-.002-.004a.5.5 0 01.838-.524c.601.7 1.516 1.168 2.496 1.168a3.25 3.25 0 10-.139-6.498l-.699.03.199-.671c.14-.47.14-.745.139-.927V4.25A3.25 3.25 0 008 1zm2.207 12.024c.225.405.487.848.78 1.294C11.437 15 10.975 16 10.1 16H5.9c-.876 0-1.338-1-.887-1.683.291-.442.552-.88.776-1.283a4.25 4.25 0 11-2.007-8.187 2.79 2.79 0 01-.009-.064c-.023-.187-.023-.348-.023-.52V4.25a4.25 4.25 0 018.5 0c0 .14 0 .333-.04.596a4.25 4.25 0 01-.46 8.476 4.186 4.186 0 01-1.543-.298z' />
		</svg>
	);
}

export default SvgSuitClub;
