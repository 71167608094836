import * as React from 'react';

function SvgVpnLock(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M12 8h-2v2c0 .55-.45 1-1 1H7v2h6c.55 0 1 .45 1 1v3h1c.9 0 1.64.58 1.9 1.39C18.2 16.97 19 15.08 19 13c0-.34-.04-.67-.08-1H17c-1.65 0-3-1.35-3-3V6c0 1.1-.9 2-2 2zm-4 9v-1l-4.79-4.79C3.08 11.79 3 12.38 3 13c0 4.08 3.05 7.44 7 7.93V19c-1.1 0-2-.9-2-2z'
				opacity={0.3}
			/>
			<path d='M18.92 12c.04.33.08.66.08 1 0 2.08-.8 3.97-2.1 5.39-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H7v-2h2c.55 0 1-.45 1-1V8h2c1.1 0 2-.9 2-2V3.46c-.95-.3-1.95-.46-3-.46C5.48 3 1 7.48 1 13s4.48 10 10 10 10-4.48 10-10c0-.34-.02-.67-.05-1h-2.03zM10 20.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L8 16v1c0 1.1.9 2 2 2v1.93zM22 4v-.5a2.5 2.5 0 00-5 0V4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h5c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1zm-1 0h-3v-.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5V4z' />
		</svg>
	);
}

export default SvgVpnLock;
