import * as React from 'react';

function SvgWifiOff(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M10.706 3.294A12.545 12.545 0 008 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 00-.048.736.518.518 0 00.668.05A11.448 11.448 0 018 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 00-.063.745.525.525 0 00.652.065 8.448 8.448 0 013.51-1.27L8 6zm2.596 1.404l.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 01.063.745.525.525 0 01-.652.065 8.462 8.462 0 00-1.98-.932zM8 10l.933-.933a6.455 6.455 0 012.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 01-.611.09A5.478 5.478 0 008 10zm4.905-4.905l.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 01.047.737.518.518 0 01-.668.05 11.493 11.493 0 00-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 01-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 018 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 01.75.75l-10.75 10.75a.53.53 0 01-.75-.75l10.75-10.75z' />
		</svg>
	);
}

export default SvgWifiOff;
