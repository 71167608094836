import * as React from 'react';

function SvgSunglasses(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M3 5a2 2 0 00-2 2v.5H.5a.5.5 0 000 1H1V9a2 2 0 002 2h1a3 3 0 003-3 1 1 0 112 0 3 3 0 003 3h1a2 2 0 002-2v-.5h.5a.5.5 0 000-1H15V7a2 2 0 00-2-2h-2a2 2 0 00-1.888 1.338A1.99 1.99 0 008 6a1.99 1.99 0 00-1.112.338A2 2 0 005 5H3zm0 1h.941c.264 0 .348.356.112.474l-.457.228a2 2 0 00-.894.894l-.228.457C2.356 8.289 2 8.205 2 7.94V7a1 1 0 011-1z' />
		</svg>
	);
}

export default SvgSunglasses;
