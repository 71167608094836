import * as React from 'react';

function SvgGearWide(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 01-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 01-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 01-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 01.434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 011.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 011.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 011.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 011.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 01.434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 01-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 01-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 01-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 110-9.995 4.998 4.998 0 010 9.996z' />
		</svg>
	);
}

export default SvgGearWide;
