import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const getAccessKPIReport = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}general-setting/access-kpi-report`, {
		headers: await authHeader(),
		params: payload,
	});
};

const getAccessInputKPI = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}general-setting/access-input-kpi`, {
		headers: await authHeader(),
		params: payload,
	});
};

const getAccessPAReport = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}general-setting/access-pa-report`, {
		headers: await authHeader(),
		params: payload,
	});
};

const getAccessDocument = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}general-setting/access-document`, {
		headers: await authHeader(),
		params: payload,
	});
};
export default { getAccessKPIReport, getAccessInputKPI, getAccessPAReport, getAccessDocument };
