import axios from 'axios';
import authHeader from '../auth-header';

const API = process.env.REACT_APP_API;

const index = {};

index.read = async (query) => {
	return axios.get(`${API}document-priority`, { headers: await authHeader(), params: query });
};

export default index;
