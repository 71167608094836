import * as React from 'react';

function SvgEmojiSunglasses(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M4.968 9.75a.5.5 0 10-.866.5A4.498 4.498 0 008 12.5a4.5 4.5 0 003.898-2.25.5.5 0 10-.866-.5A3.498 3.498 0 018 11.5a3.498 3.498 0 01-3.032-1.75zM7 5.116V5a1 1 0 00-1-1H3.28a1 1 0 00-.97 1.243l.311 1.242A2 2 0 004.561 8H5a2 2 0 001.994-1.839A2.99 2.99 0 018 6c.393 0 .74.064 1.006.161A2 2 0 0011 8h.438a2 2 0 001.94-1.515l.311-1.242A1 1 0 0012.72 4H10a1 1 0 00-1 1v.116A4.22 4.22 0 008 5c-.35 0-.69.04-1 .116z' />
			<path d='M16 8A8 8 0 110 8a8 8 0 0116 0zm-1 0A7 7 0 101 8a7 7 0 0014 0z' />
		</svg>
	);
}

export default SvgEmojiSunglasses;
