import * as React from 'react';

function SvgWater(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M.036 3.314a.5.5 0 01.65-.278l1.757.703a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.757-.703a.5.5 0 11.372.928l-1.758.703a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0L.314 3.964a.5.5 0 01-.278-.65zm0 3a.5.5 0 01.65-.278l1.757.703a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.757-.703a.5.5 0 11.372.928l-1.758.703a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0L.314 6.964a.5.5 0 01-.278-.65zm0 3a.5.5 0 01.65-.278l1.757.703a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.757-.703a.5.5 0 11.372.928l-1.758.703a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0L.314 9.964a.5.5 0 01-.278-.65zm0 3a.5.5 0 01.65-.278l1.757.703a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.014-.406a2.5 2.5 0 011.857 0l1.015.406a1.5 1.5 0 001.114 0l1.757-.703a.5.5 0 11.372.928l-1.758.703a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0l-1.014-.406a1.5 1.5 0 00-1.114 0l-1.015.406a2.5 2.5 0 01-1.857 0l-1.757-.703a.5.5 0 01-.278-.65z' />
		</svg>
	);
}

export default SvgWater;
