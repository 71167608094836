import * as React from 'react';

function SvgKeyboardFill(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M0 6a2 2 0 012-2h12a2 2 0 012 2v5a2 2 0 01-2 2H2a2 2 0 01-2-2V6zm13 .25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25zM2.25 8a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 003 8.75v-.5A.25.25 0 002.75 8h-.5zM4 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 005 8.75v-.5A.25.25 0 004.75 8h-.5a.25.25 0 00-.25.25zM6.25 8a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 007 8.75v-.5A.25.25 0 006.75 8h-.5zM8 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 009 8.75v-.5A.25.25 0 008.75 8h-.5a.25.25 0 00-.25.25zM13.25 8a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5zm0 2a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5zm-3-2a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h1.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-1.5zm.75 2.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25zM11.25 6a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5zM9 6.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5A.25.25 0 009.75 6h-.5a.25.25 0 00-.25.25zM7.25 6a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 008 6.75v-.5A.25.25 0 007.75 6h-.5zM5 6.25v.5c0 .138.112.25.25.25h.5A.25.25 0 006 6.75v-.5A.25.25 0 005.75 6h-.5a.25.25 0 00-.25.25zM2.25 6a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h1.5A.25.25 0 004 6.75v-.5A.25.25 0 003.75 6h-1.5zM2 10.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25zM4.25 10a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h5.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-5.5z' />
		</svg>
	);
}

export default SvgKeyboardFill;
