import * as React from 'react';

function SvgLine(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path
				fillRule='evenodd'
				d='M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0zM5.022 7.686H3.497V4.918a.156.156 0 00-.155-.156H2.78a.156.156 0 00-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 00.108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 00-.155-.157zm.791-2.924h.562c.086 0 .155.07.155.156v3.486c0 .086-.07.155-.155.155h-.562a.156.156 0 01-.156-.155V4.918c0-.086.07-.156.156-.156zm3.863 0h.56c.087 0 .157.07.157.156v3.486c0 .086-.07.155-.156.155h-.561a.155.155 0 01-.04-.005h-.002a.168.168 0 01-.011-.004l-.005-.002-.007-.003a.066.066 0 01-.008-.004L9.6 8.54l-.01-.006-.001-.001a.154.154 0 01-.04-.039l-1.6-2.16v2.07a.155.155 0 01-.155.156h-.561a.156.156 0 01-.156-.155V4.918c0-.086.07-.156.156-.156H7.8l.005.001h.006l.003.001h.006l.01.003h.002l.002.001.01.003.005.002a.09.09 0 01.009.004l.003.001.002.001a.113.113 0 01.013.008l.003.002.005.003v.001c.002 0 .003.002.004.003a.092.092 0 01.008.006l.003.003a.17.17 0 01.023.026L9.52 6.99V4.918c0-.086.07-.156.156-.156zm3.815.717c0 .086-.07.156-.155.156H11.81v.59h1.525c.086 0 .155.07.155.155v.561c0 .086-.07.156-.155.156H11.81v.59h1.525c.086 0 .155.07.155.155v.561c0 .086-.07.156-.155.156h-2.242a.155.155 0 01-.11-.045l-.002-.003a.155.155 0 01-.044-.107V4.918c0-.042.017-.08.043-.107l.003-.003.001-.001a.155.155 0 01.109-.045h2.242c.086 0 .155.07.155.156v.561z'
			/>
		</svg>
	);
}

export default SvgLine;
