import * as React from 'react';

function SvgColorLens(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0z' fill='none' />
			<path
				d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8c.28 0 .5-.22.5-.5a.54.54 0 00-.14-.35c-.41-.46-.63-1.05-.63-1.65a2.5 2.5 0 012.5-2.5H16c2.21 0 4-1.79 4-4 0-3.86-3.59-7-8-7zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 10 6.5 10s1.5.67 1.5 1.5S7.33 13 6.5 13zm3-4C8.67 9 8 8.33 8 7.5S8.67 6 9.5 6s1.5.67 1.5 1.5S10.33 9 9.5 9zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 6 14.5 6s1.5.67 1.5 1.5S15.33 9 14.5 9zm4.5 2.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5z'
				opacity={0.3}
			/>
			<path d='M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10a2.5 2.5 0 001.86-4.17.495.495 0 01.37-.83H16c3.31 0 6-2.69 6-6 0-4.96-4.49-9-10-9zm4 13h-1.77a2.5 2.5 0 00-2.5 2.5c0 .61.22 1.19.63 1.65.06.07.14.19.14.35 0 .28-.22.5-.5.5-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.14 8 7c0 2.21-1.79 4-4 4z' />
			<circle cx={6.5} cy={11.5} r={1.5} />
			<circle cx={9.5} cy={7.5} r={1.5} />
			<circle cx={14.5} cy={7.5} r={1.5} />
			<circle cx={17.5} cy={11.5} r={1.5} />
		</svg>
	);
}

export default SvgColorLens;
