import * as React from 'react';

const SvgChecklistBlue = (props) => (
	<svg
		width='24'
		height='28'
		viewBox='0 0 24 28'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		className='svg-icon'
		{...props}>
		<path
			d='M21.3333 3.4987H15.76C15.2 1.95203 13.7333 0.832031 12 0.832031C10.2667 0.832031 8.8 1.95203 8.24 3.4987H2.66667C1.95942 3.4987 1.28115 3.77965 0.781048 4.27975C0.280951 4.77984 0 5.45812 0 6.16536V24.832C0 25.5393 0.280951 26.2176 0.781048 26.7176C1.28115 27.2177 1.95942 27.4987 2.66667 27.4987H21.3333C22.0406 27.4987 22.7189 27.2177 23.219 26.7176C23.719 26.2176 24 25.5393 24 24.832V6.16536C24 5.45812 23.719 4.77984 23.219 4.27975C22.7189 3.77965 22.0406 3.4987 21.3333 3.4987ZM12 3.4987C12.3536 3.4987 12.6928 3.63917 12.9428 3.88922C13.1929 4.13927 13.3333 4.47841 13.3333 4.83203C13.3333 5.18565 13.1929 5.52479 12.9428 5.77484C12.6928 6.02489 12.3536 6.16536 12 6.16536C11.6464 6.16536 11.3072 6.02489 11.0572 5.77484C10.8071 5.52479 10.6667 5.18565 10.6667 4.83203C10.6667 4.47841 10.8071 4.13927 11.0572 3.88922C11.3072 3.63917 11.6464 3.4987 12 3.4987ZM5.33333 8.83203H18.6667V6.16536H21.3333V24.832H2.66667V6.16536H5.33333V8.83203ZM6 17.4987L8 15.4987L10.6667 18.1654L16.6667 12.1654L18.6667 14.1654L10.6667 22.1654L6 17.4987Z'
			fill='#4D69FA'
		/>
	</svg>
);

export default SvgChecklistBlue;
