import React, { useState, useRef, useEffect } from 'react';
import { useMeasure } from 'react-use';
import Swal from 'sweetalert2';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '../../components/bootstrap/Modal';
import Button from '../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../components/bootstrap/OffCanvas';
import Spinner from '../../components/bootstrap/Spinner';
import Chat, { ChatGroup, ChatAvatar } from '../../components/ChatBot';
import InputGroup from '../../components/bootstrap/forms/InputGroup';
import Textarea from '../../components/bootstrap/forms/Textarea';
import USERS from '../../common/data/userDummyData';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import ChatModule from '../../modules/ChatModule';
import { getRequester } from '../../helpers/helpers';
import useDarkMode from '../../hooks/useDarkMode';
import Icon from '../../components/icon/Icon';
import Label from '../../components/bootstrap/forms/Label';
import ComplainFormModule from '../../modules/sodiq/ComplainFormModule';
import showNotification from '../../components/extras/showNotification';

const ChatLoading = () => {
	const { darkModeStatus } = useDarkMode();

	return (
		<div className='d-flex flex-row'>
			<ChatAvatar
				src={USERS.ELLEN.src}
				srcSet={USERS.ELLEN.srcSet}
				username={USERS.ELLEN.username}
				name={USERS.ELLEN.name}
				surname={USERS.ELLEN.surname}
				isOnline={USERS.ELLEN.isOnline}
				color={USERS.ELLEN.color}
			/>
			<div className='chat-message d-flex flex-row align-items-center justify-content-center ml-2'>
				<Spinner color={darkModeStatus ? 'light' : 'dark'} isGrow isSmall />
				<Spinner color={darkModeStatus ? 'light' : 'dark'} isGrow isSmall />
				<Spinner color={darkModeStatus ? 'light' : 'dark'} isGrow isSmall />
			</div>
		</div>
	);
};

const Ellen = () => {
	const bottomRef = useRef(null);
	const { username } = getRequester();
	const [state, setState] = useState(false);
	const [chats, setChats] = useState([]);
	const [chatTyped, setChatTyped] = useState('');
	const [loading, setLoading] = useState(false);
	const [maxChat, setMaxChat] = useState({ prompt_count: null, max_chat: null, month: null });
	const [startTyping, setStartTyping] = useState(false);

	useEffect(() => {
		if (state) {
			readChat();
		}
	}, [state]);

	useEffect(() => {
		if (bottomRef.current) {
			bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
		}
	}, [chats]);

	const getMaxChat = async () => {
		return ChatModule.maxChat(`username=${username}`)
			.then((res) => {
				setMaxChat(res);
			})
			.catch((err) => {
				Swal.fire({
					title: <strong>Error</strong>,
					html: <i>{err}</i>,
					icon: 'error',
				});
			})
			.finally(() => {});
	};

	const readChat = async () => {
		return ChatModule.readChat(`username=${username}`)
			.then((res) => {
				const cht = res.map((c) => {
					if (c.username === 'Ellen') {
						return { ...c, user: USERS.ELLEN };
					}
					return { ...c, user: USERS.JOHN };
				});
				setChats(cht);
			})
			.catch((err) => {
				Swal.fire({
					title: <strong>Error</strong>,
					html: <i>{err}</i>,
					icon: 'error',
				});
			})
			.finally(() => {
				getMaxChat();
			});
	};

	const sendChat = () => {
		const obj = {
			id: chats.length + 1,
			messages: chatTyped,
			user: USERS.JOHN,
			isReply: true,
		};
		setChatTyped('');
		setChats([...chats, obj]);
		setLoading(true);
		return ChatModule.send({ username, prompt: chatTyped })
			.then((res) => {
				if (res.message) {
					const temp = [...chats];
					temp.pop();
					setChats([...temp]);
					Swal.fire({
						title: 'Chat failed',
						text: 'Please try again!',
						icon: 'error',
					});
				} else {
					const cht = res.map((c) => {
						if (c.username === 'Ellen') {
							return { ...c, user: USERS.ELLEN };
						}
						return { ...c, user: USERS.JOHN };
					});
					setLoading(false);
					setStartTyping(true);
					setChats(cht);
				}
			})
			.catch(() => {
				setLoading(false);
			})
			.finally(() => {
				getMaxChat();
			});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter' && (chatTyped === '' || loading)) {
			event.preventDefault();
			return;
		}
		if (
			event.key === 'Enter' &&
			chatTyped !== '' &&
			maxChat.prompt_count <= maxChat.max_chat &&
			!loading
		) {
			event.preventDefault();
			sendChat();
		}
	};

	return (
		<div
			style={{ position: 'absolute', top: -40 }}
			className='col align-self-center d-flex justify-content-end d-none'>
			<Button
				onClick={() => {
					setState(!state);
				}}
				isLight
				isOutline
				shadow='lg'
				hoverShadow='none'
				icon='chat'
				color='primary'
				className='border border-primary'>
				Chat with Ellen
			</Button>
			<OffCanvas
				style={{ width: '30%', overflowX: 'none' }}
				id='chat'
				isOpen={state}
				setOpen={setState}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setState} className='fs-5 text-light fw-bold'>
					Chat with Ellen
				</OffCanvasHeader>
				<OffCanvasBody ref={bottomRef}>
					<Chat>
						{chats.map((msg, idx) => (
							<ChatGroup
								key={msg.id}
								messages={msg.messages}
								user={msg.user}
								isReply={msg.isReply}
								bottomRef={chats.length === idx + 1 ? bottomRef : null}
								startTyping={
									startTyping && chats.length === idx + 1 && !msg.isReply
								}
								onFinishTyping={(finish) => finish && setStartTyping(false)}
							/>
						))}
					</Chat>
					{loading && (
						<div className='chat-messages'>
							<ChatLoading />
						</div>
					)}
				</OffCanvasBody>
				<div className='d-flex justify-content-center align-items-end w-100 px-3 mt-3'>
					<Alert isLight color='info'>
						<>
							{(chats?.length <= 0 || chats[0]?.message?.length <= 0) && (
								<AlertHeading tag='h5' className='h5'>
									Welcome to Ellen ChatBot &#127881;
								</AlertHeading>
							)}
							<p
								className={
									chats?.length <= 0 || chats[0]?.message?.length <= 0
										? ''
										: 'text-center'
								}>
								Powered by <b>ChatGPT</b>
								<br /> You used
								<b>
									{' '}
									{maxChat.prompt_count} of {maxChat.max_chat}
								</b>{' '}
								prompt during
								<b> {moment().format('MMMM YYYY')}</b>{' '}
							</p>
						</>
					</Alert>
				</div>
				<div className='chat-send-message p-3'>
					<InputGroup>
						<Textarea
							style={{ maxHeight: '10vh', resize: 'none' }}
							disabled={maxChat.prompt_count >= maxChat.max_chat}
							placeholder={
								maxChat.prompt_count >= maxChat.max_chat
									? "You've reached your limit!"
									: 'Ask me anything'
							}
							onKeyDown={handleKeyPress}
							value={chatTyped}
							onChange={(e) => {
								setChatTyped(e.target.value);
							}}
						/>
						<Button
							disabled={
								chatTyped === '' ||
								maxChat.prompt_count >= maxChat.max_chat ||
								loading
							}
							onClick={sendChat}
							color='info'
							icon='Send'>
							Send
						</Button>
					</InputGroup>
				</div>
			</OffCanvas>
		</div>
	);
};

const Footer = () => {
	const [ref, { height }] = useMeasure();
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [description, setDescription] = useState(null);
	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);
	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			['link', 'image'],
			['clean'],
		],
	};

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
	];

	const handleOpenComplain = () => {
		setDescription(null);
		setIsOpen(true);
	};

	const handleSubmit = () => {
		if (description) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Please check your entries !',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then((result) => {
				if (result.value) {
					setLoading(true);
					const { username, person_name, email, department } = getRequester();
					const payload = {
						username,
						name: person_name,
						email,
						department,
						url: window.location.href,
						description,
					};
					ComplainFormModule.create(payload)
						.then(() => {
							showNotification(
								'Success!',
								'Data has been saved Successfully',
								'success',
							);
							setIsOpen(false);
							setLoading(false);
							setDescription(null);
						})
						.catch((err) => {
							showNotification('Warning!', err, 'danger');
							setLoading(false);
						});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire('Cancelled', 'Your data is safe :)', 'error');
				}
			});
		} else {
			showNotification('Warning!', 'Please Entries Description', 'danger');
		}
	};

	return (
		<>
			<footer ref={ref} className='footer'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col align-self-center'>
							<Button tag='a' className='fw-light border-0'>
								Copyright © 2022 - Version 0.1
							</Button>
							<span
								aria-hidden='true'
								style={{
									cursor: 'pointer',
									color: '#4d69fa',
									marginLeft: '10px',
									textDecoration: 'underline',
								}}
								onClick={() => handleOpenComplain()}>
								<Icon icon='Bug' size='lg' color='info' /> Report bug
							</span>
						</div>
						<Ellen />
					</div>
				</div>
			</footer>
			<Modal
				isStaticBackdrop
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='lg'
				titleId='modal-input'
				isScrollable
				isCentered>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id='modal-complain'>Report Bug Form</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row' style={{ marginTop: '-25px' }}>
						<div className='col-12 mt-3'>
							<Label style={{ marginBottom: '-5px' }}>Description</Label>
							<div className='text-editor'>
								<ReactQuill
									theme='snow'
									modules={modules}
									formats={formats}
									value={description}
									onChange={setDescription}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<div className='col-md-12 ' style={{ display: !loading ? 'block' : 'none' }}>
						<Button
							style={{ marginLeft: '5px' }}
							onClick={() => {
								setIsOpen(false);
							}}
							icon='Cancel'
							type='button'
							color='warning'
							className='float-start'>
							Cancel
						</Button>
						<Button
							onClick={() => handleSubmit()}
							icon='Save'
							type='button'
							color='success'
							className='float-end'>
							Submit
						</Button>
					</div>
					<div className='col-md-12' style={{ display: loading ? 'block' : 'none' }}>
						<Label
							style={{ marginLeft: '5px', marginTop: '5px' }}
							className='float-end'>
							Processing saved data...
						</Label>
						<Spinner className='float-end' color='success' role='status' size='md' />
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Footer;
