import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { decode } from 'html-entities';

const numberArray = new Uint32Array(10);

export function test() {
	return null;
}

export const cookieNameGenerator = (name) => {
	try {
		if (process.env.REACT_APP_ENV === 'production') {
			return name;
		}
		return `${process.env.REACT_APP_ENV}-${name}`;
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getMenu = () => {
	try {
		const menu = JSON.parse(localStorage.getItem('menu'));
		return menu;
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getRequester = () => {
	try {
		const acToken = JSON.parse(localStorage.getItem('accessToken'));
		const position = JSON.parse(localStorage.getItem('position'));
		const photo = JSON.parse(localStorage.getItem('photo'));
		const decodedToken = jwt_decode(acToken.accessToken);
		const { details, username } = decodedToken;

		const result = {
			username,
			email: details.hris_org_tree?.current_person?.email,
			department: details.hris_org_tree?.current_person?.nama_department,
			person_name: details.hris_org_tree?.current_person?.person_name,
			position_name: position?.position_name,
			position_code: position?.position_code,
			position,
			photo,
		};

		if (details?.version) {
			result.version = details.version;
		}

		return result;
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getRoles = () => {
	try {
		const roles = JSON.parse(localStorage.getItem('roles'));

		return { roles };
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getaActiveRoles = () => {
	try {
		const roles = JSON.parse(localStorage.getItem('active_role'));
		return {
			default_role_name: roles.default_role_name,
			default_role_code: roles.default_role_code,
			default_segment_code: roles.default_segment_code,
			default_segment_name: roles.default_segment_name,
		};
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getActiveRoles = () => {
	try {
		const roles = JSON.parse(localStorage.getItem('active_role'));
		return {
			default_role_name: roles.default_role_name,
			default_role_code: roles.default_role_code,
			default_segment_code: roles.default_segment_code,
			default_segment_name: roles.default_segment_name,
		};
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getPositions = () => {
	try {
		const positions = JSON.parse(localStorage.getItem('positions'));
		const role = JSON.parse(localStorage.getItem('roles'));
		const position = JSON.parse(localStorage.getItem('position'));
		return {
			roles: positions.map((item) => ({
				...item,
				is_selected:
					item.role_name === role && item.position_code === position.position_code,
			})),
		};
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export const getRouting = (data) => {
	try {
		const rawRoute = Object.keys(data)
			.map((item) => {
				if (data[item].subMenu) {
					return Object.keys(data[item].subMenu).map((sb) => {
						return {
							path: data[item].subMenu[sb].path,
							element: data[item].subMenu[sb].element,
						};
					});
				}
				return null;
			})
			.filter((e) => e);
		const merge = [].concat(...rawRoute);
		return merge;
	} catch (err) {
		localStorage.clear();
		return window.location.reload();
	}
};

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(crypto.getRandomValues(numberArray) * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const isEmptyObject = (obj) => {
	return obj == null || !Object.keys(obj).length;
};

export const ObjectReader = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);

export const UpdateObject = (data, path, value, push = false) => {
	let schema = data; // a moving reference to internal objects within obj
	const pList = path.split('.');
	const len = pList.length;
	for (let i = 0; i < len - 1; i++) {
		const elem = pList[i];
		if (!schema[elem]) schema[elem] = {};
		schema = schema[elem];
	}

	if (push) {
		schema[pList[len - 1]].push(value);
	} else {
		schema[pList[len - 1]] = value;
	}
};

/**
 * Getting options, when use custom select component
 * @param data
 * @param text
 * @param value
 * @param others
 * @param showAll
 * @returns {[{label: string, value: string}]}
 */
export const getOptionsCustomSelect = (
	data,
	text,
	value,
	others = [],
	showAll = { active: false, label: 'Show All', value: 'all' },
) => {
	const temp = [{ label: 'Select one', value: '' }];
	if (showAll.active) {
		temp.push({ label: showAll.label, value: showAll.value });
	}
	data.forEach((dt) => {
		const template = {
			label: '',
			value: '',
			detail: dt,
		};
		if (typeof text === 'string') {
			template.label = dt[text];
			template.value = dt[value];
		} else if (typeof text === 'object') {
			let fixLabel = '';
			text.items.forEach((path, index) => {
				if (index) {
					fixLabel += `${text.hyphen}${ObjectReader(dt, path)}`;
				} else {
					fixLabel += `${ObjectReader(dt, path)}`;
				}
			});
			template.label = fixLabel;
			template.value = dt[value];
		}

		others.forEach((ot) => {
			const split = ot.split(':');
			const key = split[0];
			const path = split[1];
			template[key] = ObjectReader(dt, path);
		});

		temp.push(template);
	});

	return temp;
};

/**
 * example:
 * const initObject = { value: 5, next: { value: 10, next: { value: 15, next: null } } };
 * const newObject = ReverseNestedObject(initObject, 'next', 'children')


 *
 * output:
 * {
 *   "value": 15,
 *   "children": {
 *     "value": 10,
 *     "children": {
 *       "value": 5,
 *       "children": null
 *     }
 *   }
 * }
 *
 * @param data
 * @param nestedKey
 * @param newNestedKey
 * @returns {null}
 * @constructor
 */
export const ReverseNestedObject = (data, nestedKey = '', newNestedKey = '') => {
	let newObject = null;

	for (let o = data; o; o = o[nestedKey]) {
		newObject = {
			[newNestedKey || nestedKey]: newObject,
		};

		// eslint-disable-next-line no-loop-func
		Object.entries(o).forEach(([key, val]) => {
			if (key !== nestedKey) {
				newObject[key] = val;
			}
		});
	}

	return newObject;
};

export const getQueryParams = (query = null) => {
	return (
		(query || window.location.search.replace('?', ''))

			// get array of KeyValue pairs
			.split('&')

			// Decode values
			.map((pair) => {
				const [key, val] = pair.split('=');

				return [key, decodeURIComponent(val || '')];
			})

			// array to object
			.reduce((result, [key, val]) => {
				result[key] = val;
				return result;
			}, {})
	);
};

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length) => {
	let result = ' ';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(crypto.getRandomValues(numberArray) * charactersLength),
		);
	}

	return result;
};

export const getSemester = () => {
	const year = moment().year();
	const month = moment().month();
	return month > 7 ? `Semester II ${year}` : `Semester I ${year}`;
};

export const removeReactivity = (state) => {
	return JSON.parse(JSON.stringify(state));
};

export function isNumeric(str) {
	if (typeof str !== 'string') return false; // we only process strings!
	return (
		!Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!Number.isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}

const containsHtmlEntities = (str) => {
	// Regular expressions to check for named, numeric, and hex entities
	const entityPattern = /&[a-zA-Z]+;|&#[0-9]+;|&#x[0-9A-Fa-f]+;/;
	return entityPattern.test(str);
};

export const decodeHtmlEntities = (obj) => {
	if (typeof obj === 'string') {
		let decodedStr = decode(obj);
		while (containsHtmlEntities(decodedStr)) {
			decodedStr = decode(decodedStr);
		}
		return decodedStr;
	}
	if (Array.isArray(obj)) {
		return obj.map((item) => decodeHtmlEntities(item));
	}
	if (typeof obj === 'object' && obj !== null) {
		return Object.keys(obj).reduce((acc, key) => {
			acc[key] = decodeHtmlEntities(obj[key]);
			return acc;
		}, {});
	}
	return obj;
};
