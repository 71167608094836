import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../components/bootstrap/Card';
import CustomAsyncSelect from '../components/custom/CustomAsyncSelect';
import Input from '../components/bootstrap/forms/Input';
import Button from '../components/bootstrap/Button';
import DocumentModule from '../modules/afif/DocumentModule';
import { getRequester } from '../helpers/helpers';
import showNotification from '../components/extras/showNotification';
import { fetchDataAsyncSelect, loadOptionsDepartment } from './sodiq/GeneralFunction';
import GeneralSettingModule from '../modules/GeneralSettingModule';

const CardDocument = ({ categoryDoc, totalDoc, distributedDoc, onProgressDoc }) => {
	const navigate = useNavigate();
	const detail = (cat) => {
		localStorage.setItem('category_detail', cat);
		navigate('/document/my-document');
	};
	return (
		<div className='col-xl-3 col-md-6'>
			<Card>
				<CardHeader
					className='dashboard'
					onClick={() => detail(categoryDoc)}
					style={{ cursor: 'pointer' }}>
					<CardLabel icon='FileCopy' iconColor='info'>
						<CardTitle>{categoryDoc}</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody>
					<div className='row text-center mt-2'>
						<div className='col-4'>
							<h2 className='text-info'>{totalDoc}</h2>
							<p>Total Document</p>
						</div>
						<div className='col-4 border-start border-end'>
							<h2 className='text-success'>{distributedDoc}</h2>
							<p>Distributed</p>
						</div>
						<div className='col-4'>
							<h2 className='text-warning'>{onProgressDoc}</h2>
							<p>On Progress</p>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

const FormSearch = (dt) => {
	const { setFilter, departmentList, isAdmin } = dt;
	const { department } = useMemo(() => getRequester(), []);
	const [searchValue, setSearchValue] = useState(null);
	const [searchDepartment, setSearchDepartment] = useState(null);

	const inputDepartment = useRef(null);
	const inputSearch = useRef(null);
	const defaultValues = { label: 'All', value: 'All' };
	const currentDept = { label: department, value: department };

	useEffect(() => {
		if (isAdmin) {
			setSearchDepartment(defaultValues);
			inputDepartment.current?.setValue(defaultValues);
		} else {
			setSearchDepartment(currentDept);
			inputDepartment.current?.setValue(currentDept);
		}
	}, [isAdmin]);

	const handleSearch = () => {
		const init = {};
		if (searchValue !== null) {
			init.search = searchValue;
		}
		if (searchDepartment && searchDepartment?.value !== 'All') {
			init.department = searchDepartment.value;
		}

		setFilter(init);
	};

	const handleReset = () => {
		const init = {};
		inputSearch.current.value = '';
		setSearchValue(null);
		if (isAdmin) {
			setSearchDepartment(defaultValues);
			inputDepartment.current?.setValue(defaultValues);
		} else {
			setSearchDepartment(currentDept);
			inputDepartment.current?.setValue(currentDept);
		}

		setFilter(init);
	};

	return (
		<div className='col-12 mb-3 g-4'>
			<div className='row'>
				<div className='col-2'>
					<label>Department</label>
					<CustomAsyncSelect
						theme='info'
						ref={inputDepartment}
						defaultOptions={departmentList}
						loadOptions={loadOptionsDepartment}
						cacheOptions
						onChange={(e) => {
							setSearchDepartment(e);
						}}
						value={searchDepartment}
						placeholder='Type here ...'
					/>
				</div>
				<div className='col-2 mt-4'>
					<Input
						ref={inputSearch}
						autoComplete='off'
						type='text'
						id='search'
						placeholder='Search by Category'
						onChange={(e) => setSearchValue(e.target.value)}
						value={searchValue}
					/>
				</div>
				<div className='col-4 mt-4'>
					<Button
						style={{ marginLeft: '5px' }}
						icon='search'
						type='button'
						color='primary'
						onClick={() => {
							handleSearch();
						}}
						className='float-start'>
						Filter
					</Button>
					<Button
						style={{ marginLeft: '5px' }}
						icon='history'
						type='button'
						color='warning'
						onClick={() => {
							handleReset();
						}}
						className='float-start'>
						Reset
					</Button>
				</div>
			</div>
		</div>
	);
};

const Dashboard = () => {
	const [documents, setDocuments] = useState([]);
	const [filter, setFilter] = useState(null);
	const [departmentList, setDepartmentList] = useState([]);
	const { department, username } = useMemo(() => getRequester(), []);
	const [isAdmin, setIsAdmin] = useState(false);

	const fetchDataSelect = async (is_admin = false) => {
		let findDept = '';
		if (!is_admin) {
			findDept = department;
		}
		const resultDept = await fetchDataAsyncSelect(findDept, 'dept', true);
		setDepartmentList(resultDept);
	};

	const fetchCountDocuments = async (nFilter = {}, is_admin = false) => {
		if (!is_admin) {
			const total = await DocumentModule.countByCategory({
				...nFilter,
				department,
			})
				.then((value) => value)
				.catch((err) => {
					showNotification('Warning!', err, 'danger');
				});

			setDocuments(total);
		} else {
			const total = await DocumentModule.countByCategory(nFilter)
				.then((value) => value)
				.catch((err) => {
					showNotification('Warning!', err, 'danger');
				});

			setDocuments(total);
		}
	};

	useEffect(() => {
		GeneralSettingModule.getAccessDocument({ username })
			.then((res) => {
				fetchCountDocuments(filter, res.allowed);
				fetchDataSelect(res.allowed);
				setIsAdmin(res.allowed);
			})
			.catch(() => {
				fetchCountDocuments(filter, false);
				fetchDataSelect(false);
				setIsAdmin(false);
			});
	}, [filter]);

	return (
		<PageWrapper title='Dashboard'>
			<PageLayoutHeader />
			<Page container='fluid'>
				<div className='row justify-content-center'>
					<Card stretch>
						<CardBody>
							<FormSearch
								setFilter={setFilter}
								departmentList={departmentList}
								isAdmin={isAdmin}
							/>
							{documents.length > 0 ? (
								<div className='row mt-4'>
									{documents.map((item, idx) => (
										<CardDocument
											key={idx}
											categoryDoc={item._id}
											totalDoc={item.total_doc}
											distributedDoc={item.distributed}
											onProgressDoc={item.on_progress}
										/>
									))}
								</div>
							) : (
								<h4 className='text-center mt-4'>Data Not Found</h4>
							)}
						</CardBody>
					</Card>
				</div>
			</Page>
		</PageWrapper>
	);
};

CardDocument.propTypes = {
	categoryDoc: PropTypes.string,
	totalDoc: PropTypes.number,
	distributedDoc: PropTypes.number,
	onProgressDoc: PropTypes.number,
};

CardDocument.defaultProps = {
	categoryDoc: '',
	totalDoc: 0,
	distributedDoc: 0,
	onProgressDoc: 0,
};

export default Dashboard;
